.pac-container{
   padding: 20px;
    border-radius: 10px;
}

.pac-icon{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding:10px;
    background-image: url(https://images.woosmap.com/icons/locality.svg);
}

.pac-item{
    margin: 10px 10px;
    border: none;
}

.pac-item:hover .pac-icon{
    background-image: url(https://images.woosmap.com/icons/locality-selected.svg);
}

.pac-matched {
    font-size: 18px;
    color: #61dafb;
}

.pac-item-query{
    font-size: 14px;
}

