.MuiDialog-paperWidthMd {
    width: 800px !important;
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    scrollbar-width: thin;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0477a8;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0477a8;
    border-radius: 10px;
}