.otpContainer {
    margin: 5% auto;
    width: fit-content;
}

.otpInput {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #61dafb;
    opacity: 1;

}

.otpInput:focus{
    border: 2px solid #0477a8;
}