.react-flow__node:hover{
    border:solid 1px #c3bef6 !important;
}

.react-flow__handle{
    background-color: #0477a8 !important;
    width: 12px;
    height: 12px;
}

.react-flow__handle:hover{
    background-color: #0477a8 !important;
    width: 16px;
    height: 16px;
}

.react-flow__handle-right{
    right:-6px
}

.react-flow__handle-right:hover{
    right:-8px
}

.react-flow__controls{
    bottom: 243px !important;
}
.react-flow__edges{
    z-index:4 !important;
}

.dndflow {
    flex-direction: column;
    display: flex;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside > * {
    margin-bottom: 10px;
    cursor: grab;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        /*width: 20%;*/
        /*max-width: 180px;*/
    }
}